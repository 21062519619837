import React, { createContext, useState } from 'react'

import type { OptionalPageFeatures } from '@cms/utils/types'
import SiteStructure from '@knauf-group/ct-shared-nextjs/web/siteStructure/siteStructure'

type GlobalContextProps = {
  siteStructure: SiteStructure | null
  setSiteStructure: (siteStructure: SiteStructure) => void
  optionalPageFeatures?: OptionalPageFeatures
  setOptionalPageFeatures: (optionalPageFeatures: OptionalPageFeatures) => void
}

export const GlobalContext = createContext<GlobalContextProps>({
  siteStructure: null,
  setSiteStructure: () => {
    // default setter function intentionally does nothing
  },
  /**
   * optionalPageFeatures is used to toggle optional page features on/off
   * For example the cookie page cookie info table is only loaded if the FEATURE_SHOW_COOKIE_LIST_TABLE is set to true
   * These features are set in the CMS on the page entry via a tag and then set in the FE through global context from page
   * via the optionalPageFeatures prop. Any component that needs to know if a feature is enabled can use global context.
   */
  optionalPageFeatures: {},
  setOptionalPageFeatures: () => {
    // default setter function intentionally does nothing
  },
})

export const GlobalContextProvider = ({ children }) => {
  const [currentSiteStructure, setCurrentSiteStructure] = useState<SiteStructure>(
    new SiteStructure(),
  )
  const [currentOptionalPageFeatures, setCurrentOptionalPageFeatures] =
    useState<OptionalPageFeatures>({})

  return (
    <GlobalContext.Provider
      value={{
        siteStructure: currentSiteStructure,
        setSiteStructure: setCurrentSiteStructure,
        optionalPageFeatures: currentOptionalPageFeatures,
        setOptionalPageFeatures: setCurrentOptionalPageFeatures,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
