import Image from 'next/image'
import Link from 'next/link'

import type { CmsComponentsForAnalytics } from '@cms/analytics/types'
import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import useFetchRefWithOnClick from '@cms/hooks/useFetchRefWithOnClick'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { FALLBACK_IMAGES } from '@cms/utils/constants'
import { getImageWithFallback, getSmartCropBackgroundImageLoader } from '@cms/utils/utils'
import {
  CTARepeater,
  type CTARepeaterProps,
} from '@knauf-group/ct-designs/components/core/CTARepeater'
import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import type { AnalyticsTargetType } from '@knauf-group/ct-shared-nextjs/web/analytics/types'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import { linkToReferenceProps } from '@knauf-group/ct-shared-nextjs/web/utils/utils'
import { useTheme } from '@mui/material/styles'

import type { CTARepeaterWrapperProps } from './CTARepeater.types'

const CTARepeaterWrapper: React.FC<CTARepeaterWrapperProps> = (props) => {
  const theme = useTheme()
  const { devicePixelRatio } = useDevicePixelRatio()
  const { siteStructure } = useGlobalContext()

  const { fields: fieldsFromContentful } = props
  const { primaryReference, secondaryReference, image } = fieldsFromContentful || {}
  const primaryRef: ReferenceWeb = linkToReferenceProps(primaryReference, siteStructure)
  const cmsComponent: CmsComponentsForAnalytics = 'content_cta_repeater'
  const targetType: AnalyticsTargetType = 'cta'
  const primaryRefWithOnClick = useFetchRefWithOnClick(primaryRef, targetType, cmsComponent)
  const secondaryRef: ReferenceWeb = linkToReferenceProps(secondaryReference, siteStructure)
  const secondaryRefWithOnClick = useFetchRefWithOnClick(secondaryRef, targetType, cmsComponent)
  // imageWithFallback should only be included if image is set in Contentful
  const imageWithFallback =
    image?.[0] && getImageWithFallback(image?.[0], FALLBACK_IMAGES.BACKGROUND)

  const componentProps: CTARepeaterProps = {
    ...fieldsFromContentful,
    primaryRef: primaryRefWithOnClick,
    secondaryRef: secondaryRefWithOnClick,
    nextLinkComponent: Link,
    nextImageComponent: Image,
    imageLoader: devicePixelRatio
      ? getSmartCropBackgroundImageLoader(
          CONTENTFUL_TYPES.CONTENT_CTA_REPEATER,
          theme.breakpoints.values,
          devicePixelRatio,
        )
      : undefined,
    image: imageWithFallback,
    prefetch: false,
  }
  return <CTARepeater {...componentProps} />
}

export default CTARepeaterWrapper
