import { useRouter } from 'next/router'

import type {
  CmsClickEventProps,
  CmsComponentsForAnalytics,
  CmsDownloadEventProps,
} from '@cms/analytics/types'
import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import type { AnalyticsTargetType } from '@knauf-group/ct-shared-nextjs/web/analytics/types'
import {
  useClickEvent,
  useContactEvent,
  useDownloadEvent,
} from '@knauf-group/ct-shared-nextjs/web/hooks/useWebAnalytics'
import { getReferenceWithClickEvent } from '@knauf-group/ct-shared-nextjs/web/utils/utils'

import { getPageSlugFromRouterPath, isContactLink, isDownloadLink } from '../analytics/utils'

const DOCUMENT_API_REGEX = /\/api\/download-center\/v1\/assets\/([^?]*)\?/

const useFetchRefWithOnClick = (
  reference: ReferenceWeb,
  analyticsTargetType: AnalyticsTargetType,
  cmsComponent: CmsComponentsForAnalytics,
) => {
  const { push: pushContactEvent } = useContactEvent()
  const { push: pushClickEvent } = useClickEvent()
  const { push: pushDownloadEvent } = useDownloadEvent()
  const router = useRouter()

  if (!reference) {
    return reference
  }

  const pageName = getPageSlugFromRouterPath(router)
  const clickEventProps: CmsClickEventProps = {
    reference,
    type: analyticsTargetType,
    eventPayload: {
      page_name: pageName,
      page_section: 'main',
      cms_component: cmsComponent,
    },
  }

  if (isContactLink(reference)) {
    return getReferenceWithClickEvent(reference, () => pushContactEvent(clickEventProps))
  }
  if (isDownloadLink(reference)) {
    const [, assetId] = DOCUMENT_API_REGEX.exec(reference?.href)
    const downloadEventProps: CmsDownloadEventProps = {
      assetId,
      assetName: assetId,
      exportFormat: null,
      target: reference?.href,
      action: reference?.label,
      cms_component: cmsComponent,
    }

    return getReferenceWithClickEvent(reference, () => pushDownloadEvent(downloadEventProps))
  }

  return getReferenceWithClickEvent(reference, () => pushClickEvent(clickEventProps))
}

export default useFetchRefWithOnClick
